<template>
  <div>
    <div >
      <el-card >
        <div class="textureExam">
          <div slot="header">
            <p class="examRehearFont" style="margin-top: 10px">类型：</p>
            <div class="textureExamFiltrate">
              <el-tabs v-model="examTypeShow" @tab-click="changeExamType(examTypeShow)">
                <el-tab-pane label="历年真题" name="0"></el-tab-pane>
                <el-tab-pane label="专业考试" name="1"></el-tab-pane>
                <el-tab-pane label="单元测试" name="4"></el-tab-pane>
                <el-tab-pane label="答题记录" name="2"></el-tab-pane>
                <el-tab-pane label="班级测试" name="3"></el-tab-pane>
              </el-tabs>
<!--              <p v-for="(item,index) in textureExamTabArr" :key="index" :class="examTypeShow==index?'examTypeActive':''" @click="changeExamType(index)">{{item.name}}</p>-->
            </div>
          </div>
          <div v-if="!userInfo.teacherType">
            <div class="randomQuestionsFlex" @click="changeRandomExamination">
              <img src="images/Vocational2.png"/>
              <p :class="randomExam?'examTypeActive':''">随机组卷</p>
            </div>
            <div class="randomQuestionsFlex" @click="changeMistakeRecord">
              <img src="images/Vocational1.png"/>
              <p :class="erroRecord?'examTypeActive':''">错题记录</p>
            </div>
          </div>

        </div>
        <div class="topicListTypeFlex" v-if="examTypeShow==0||examTypeShow==1">
          <div>
            <p class="examRehearFont">科目：</p>
            <el-cascader
                v-model="subjectsValue"
                :options="majorListArr"
                clearable
                @change="searchList()"
            ></el-cascader>
          </div>
          <div v-if="!erroRecord">
            <el-input placeholder="请输入内容" v-model="searchExam" class="input-with-select">
              <el-button slot="append" icon="el-icon-search" @click="searchList()"></el-button>
            </el-input>
          </div>
        </div>
      </el-card>
<!--      <el-card style="margin-top: 20px" v-if="!erroRecord">-->
<!--        <div class="searchExamMr">-->
<!--          <el-input placeholder="请输入内容" v-model="searchExam" class="input-with-select">-->
<!--            <el-button slot="append" icon="el-icon-search" @click="searchList()"></el-button>-->
<!--          </el-input>-->
<!--        </div>-->
<!--      </el-card>-->
      <!--      -->
      <div class="topicListMR">
        <div :class="routePath=='/topicList'?'topicListWidth':'userClassWidth'">
          <el-card class="topicCardWidth">
            <template v-if="examTypeShow==4">
              <el-tabs v-model="activeName" type="card" @tab-click="handleClick()">
                <el-tab-pane v-for="(item,index) in coursesubject" :key="index" :label="item.name" :name="item.id"></el-tab-pane>
              </el-tabs>
            </template>
          <!--          试题列表-->
          <div v-if="questionsShow">
            <div class="examTypePading" v-for="(item,index) in collegeTemplateListArr" :key="index">
              <div class="examTypeFlex" >
                <div>
                  <div style="display: flex">
                    <p>{{item.name}}</p>
                    <div v-if="examTypeShow==3" style="margin-left: 10px">
                      <el-tag v-if="item.progressType=='TBC'" type="danger" size="mini">未提交</el-tag>
                      <el-tag v-if="item.progressType=='NR'" size="mini">未批阅</el-tag>
                      <el-tag v-if="item.progressType=='AR'" type="success" size="mini">已批阅</el-tag>
                    </div>
                  </div>
                  <p class="zhijiaoTime">{{item.createTime}}</p>
                </div>
                <!--              <el-button type="primary" v-if="examTypeIndex">重做</el-button>-->
                <div style="display: flex">
                  <el-button type="primary" @click="selectExamDetail(item)" size="small">查看</el-button>
                  <div style="margin-left: 12px" v-if="!userInfo.teacherType">
                    <el-button type="primary" size="small" @click="startExamination(item)" v-if="examTypeShow==0||examTypeShow==1||examTypeShow==4">考试</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!collegeTemplateListArr.length" style="width: 100%">
              <el-empty :image-size="200"></el-empty>
            </div>
            <div style="margin: 30px 0">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleListChange"
                  :current-page.sync="listPage"
                  :page-size="listSize"
                  layout="total,  prev, pager, next, jumper"
                  :total="listTotal"
              >
              </el-pagination>
            </div>
          </div>
          <!--          错题记录-->
          <div v-if="erroRecord">
            <div v-for="(item,index) in errorExerciseList" :key="index" class="errorRecordMr">
              <exercises-s-c
                  ref="std"
                  v-if="item.exercisesType === 'SC'"
                  :exercisesInfo="item"
                  :openType="4"
                  :isShowRightAndAnalysis="true"
                  :exercisesListIndex="index+1"
              ></exercises-s-c>
              <exercises-m-c
                  ref="std"
                  v-if="item.exercisesType === 'MC'"
                  :exercisesInfo="item"
                  :openType="4"
                  :isShowRightAndAnalysis="true"
                  :exercisesListIndex="index+1"
              ></exercises-m-c>
              <!-- 填空题 -->
              <exercises-f-b
                  ref="std"
                  v-if="item.exercisesType === 'FB'"
                  :exercisesInfo="item"
                  :openType="4"
                  :isShowRightAndAnalysis="true"
                  :exercisesListIndex="index+1"
              >
              </exercises-f-b>
              <!-- 判断题 -->
              <exercises-t-f
                  ref="std"
                  v-if="item.exercisesType=== 'TF'"
                  :exercises-info="item"
                  :open-type="4"
                  :isShowRightAndAnalysis="true"
                  :exercisesListIndex="index+1"
              ></exercises-t-f>
              <!-- 简答题 -->
              <exercises-s-a-q
                  ref="std"
                  v-else-if="item.exercisesType === 'SAQ'"
                  :exercisesInfo="item"
                  :openType="4"
                  :isShowRightAndAnalysis="true"
                  :exercisesListIndex="index+1"
              >
              </exercises-s-a-q>
              <!--            完形填空-->
              <exercises-c-t
                  ref="std"
                  v-else-if="item.exercisesType === 'CT'||item.exercisesType === 'RC'"
                  :exercisesInfo="item"
                  :openType="4"
                  :exercisesListIndex="index+1">
              </exercises-c-t>
            </div>
            <div style="margin: 30px 0">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage"
                  :page-size="pagesize"
                  layout="total,  prev, pager, next, jumper"
                  :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-card>
        </div>
        <el-card style="width:342px;margin-left: 12px;height:700px" v-if="routePath=='/topicList'">
          <stdIntroduce></stdIntroduce>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import ExercisesTF from "@/components/exercisesList/exercisesTF";
import ExercisesSC from "@/components/exercisesList/exercisesSC";
import ExercisesMC from "@/components/exercisesList/exercisesMC";
import ExercisesFB from "@/components/exercisesList/exercisesFB";
import ExercisesSAQ from "@/components/exercisesList/exercisesSAQ";
import ExercisesCT from "@/components/exercisesList/exercisesCT";
  import stdIntroduce from "@/views/vocationalExeam/stdIntroduce";
import {
  majorList,
  subjectList,
  collegeEntranceTemplateList,
  studentExamPapersList,
  studentErroExercisesList,
  studentTemplateSave
} from "@/api";
  export default{
    name:'examRehearsal',
    components:{
      stdIntroduce,
      ExercisesTF,
      ExercisesSC,
      ExercisesMC,
      ExercisesFB,
      ExercisesSAQ,
      ExercisesCT
    },
    data(){
      return{
        coursesubject:[],
        activeName:'',
        listPage:1,
        listSize:10,
        listTotal:0,
        textureExamTabArr:[
          {id:1,name:"历年真题"},
          {id:2,name:"专业考试"},
          {id:3,name:"答题记录"},
          {id:4,name:"班级测试"},
        ],
        searchExam:'',
        examTypeShow:0,
        questionsShow:true,
        erroRecord:false,
        randomExam:false,
        routePath:'',
        examTypeIndex:false,
        options: [{
          value: 'zhinan',
          label: '指南',
          children: [{
            value: 'shejiyuanze',
            label: '设计原则',
          }]
        }],
        subjectsValue:'',
        majorListArr:[],
        errorExerciseList:[],
        currentPage:1,
        pagesize:10,
        total:0,
        userInfo:{},
        collegeTemplateListArr:[]
      };

    },
    methods:{
      changeExamType(index){
        this.examTypeShow=index;
        this.questionsShow=true;
        this.erroRecord=false;
        this.randomExam=false;
        if(index==2){
          this.examTypeIndex=true
        }else{
          this.examTypeIndex=false
        }
        if(index==0||index==1){
          this.getCollegeTemplateList()
        }else if(index==4){
          this.getsubjectList()
        }else{
          this.getStudentExamPapersList()
        }
        this.subjectsValue=''
      },
      //获取学科列表
      getsubjectList(){
        subjectList().then(res=>{
          this.coursesubject=res.data.records;
          this.activeName=res.data.records[0].id
          // this.handleClick()
        })
      },
      //获取学科下的试卷列表
      handleClick(){
        let data={
          examPapersType:3,
          page:this.listPage,
          size:this.listSize,
          collegeEntranceSubjectId:this.activeName
        }
        collegeEntranceTemplateList(data).then(res=>{
          this.collegeTemplateListArr=res.data.records;
          this.listTotal=JSON.parse(res.data.total);
        })
      },
      //  显示错题记录
      changeMistakeRecord(){
        this.questionsShow=false;
        this.examTypeShow='-1';
        this.erroRecord=true;
        this.randomExam=false;
        this.getErroExercisesList()
      },
      //错题记录
      getErroExercisesList(){
        let data={
          page:this.currentPage,
          size:this.pagesize,
          mResult:'error'
        };
        studentErroExercisesList(data).then(res=>{
          this.total=JSON.parse(res.data.total);
          this.errorExerciseList=res.data.records;
        })
      },
      //  随机组卷
      changeRandomExamination(){
        let routeUrl = this.$router.resolve({
          path: "/randomExamination",
        });
        window.open(routeUrl.href);
        this.randomExam=true;
        this.questionsShow=false;
        this.erroRecord=false;
        this.examTypeShow=-1;
      },
    // 专业列表
      getMajorList(){
        let data={};
        majorList().then(res=>{
          let children=[]
          let arr=res.data.records;
          arr.forEach(item=>{
            item.label=item.name;
            item.value=item.id;
            item.children=[]
            subjectList({collegeEntranceMajorId:item.id}).then(res=>{
              res.data.records.forEach(item1=>{
                if(item.id==item1.collegeEntranceMajorId){
                  item.children.push(item1);
                }
              })
              item.children.forEach(item2=>{
                item2.label=item2.name;
                item2.value=item2.id;
              })
            });
          })
          this.majorListArr=arr;
        })
      },
    //  获取职教高考平台试卷模板列表
      getCollegeTemplateList(){
        let data={
          examPapersType:1,
          page:this.listPage,
          size:this.listSize
        }
        if(this.examTypeShow==0){
          data.examPapersType=1
        }else if(this.examTypeShow==1){
          data.examPapersType=2
        }else{
          data.examPapersType=3
        }
        if(this.searchExam){
          data.name=this.searchExam
        }
        if(this.subjectsValue){
          data.collegeEntranceMajorId=this.subjectsValue[0]
          data.collegeEntranceSubjectId=this.subjectsValue[1]
        }
        collegeEntranceTemplateList(data).then(res=>{
          this.listTotal=JSON.parse(res.data.total);
          this.collegeTemplateListArr=res.data.records;
          this.searchExam=''
        })
      },
      //搜索列表
      searchList(){
        if(this.examTypeShow==0||this.examTypeShow==1){
          this.getCollegeTemplateList()
        }else{
          this.getStudentExamPapersList()
        }

      },
      //学生查询测试试卷列表
      getStudentExamPapersList(){
        let data={
          testPaperType:1,
          page:this.listPage,
          size:this.listSize,
          queryIsOrderLately:1
        };
        if(this.examTypeShow==3){
          data.privateTeacherData=1
        }
        if(this.searchExam){
          data.examPapersTestCenterName=this.searchExam
        }
        studentExamPapersList(data).then(res=>{
          res.data.records.forEach(item=>{
            if(item.examPapersTestCenterName){
              item.name=item.examPapersTestCenterName;
            }else if(item.examPapersName){
              item.name=item.examPapersName;
            }
          })
          this.collegeTemplateListArr=res.data.records;
          this.listTotal=JSON.parse(res.data.total);
          this.searchExam=''
        })
      },
      // 分页
      handleCurrentChange: function (currentPage) {
        this.currentPage = currentPage;
        this.getErroExercisesList();
      },
      //列表分页
      handleListChange(listPage){
        this.listPage=listPage;
        if(this.examTypeShow==0||this.examTypeShow==1){
          this.getCollegeTemplateList();
        }else if(this.examTypeShow==4){
          this.handleClick()
        }else{
          this.getStudentExamPapersList()
        }
      },
    // 查看试卷详情
      selectExamDetail(item){
        if(this.userInfo.classId){
          sessionStorage.setItem('examArrDetail',JSON.stringify(item))
          if(this.examTypeShow==2){
            let routeUrl = this.$router.resolve({
              path: "/answerExam",
              query:{id:item.examPapersTestCenterId,userId:item.userId,examTypeShow:2,record:1,examTimeShow:1,exam:'exam'}
            });
            window.open(routeUrl.href);
          }else if(this.examTypeShow==3){
            let routeUrl = this.$router.resolve({
              path: "/answerExam",
              query:{id:item.examPapersTestCenterId,userId:item.userId,examTypeShow:3,record:1}
            });
            window.open(routeUrl.href);
          }else{
            let routeUrl = this.$router.resolve({
              path: "/answerExam",
              query:{id:item.id,exam:'exam'}
            });
            window.open(routeUrl.href);
          }
        }else{
          this.$alert('暂无权限，请加入班级后查看。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({
                type: 'info',
              });
            }
          });
        }
      },
    //  综合试卷开始考试
      startExamination(item,exam){
        if(this.userInfo.classId){
          sessionStorage.setItem('examArrDetail',JSON.stringify(item))
          let routeUrl = this.$router.resolve({
            path: "/answerExam",
            query:{id:item.id,synthesis:1,userId:this.userInfo.id,examTypeShow:2}
          });
          window.open(routeUrl.href);
        }else{
          this.$alert('暂无权限，请加入班级后查看。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({
                type: 'info',
              });
            }
          });
        }
      }
    },
    mounted() {
      this.getsubjectList()
      if(this.$route.query.tabIndex){
        this.examTypeShow=this.$route.query.tabIndex;
        if(this.$route.query.tabIndex==0||this.$route.query.tabIndex==1||this.$route.query.tabIndex==4){
          this.getCollegeTemplateList();
        }else{
          this.getStudentExamPapersList()
        }
      }
      if(this.$route.query.error){
        this.changeMistakeRecord()
      }
      this.routePath=this.$route.path;
      this.getMajorList();
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
    updated() {
      MathJax.typesetPromise();
    }
  }
</script>
<style>
  @import "../../../public/css/topicList.css";
</style>
