<template>
  <div>
    <el-row >
      <el-col :span="24">
        <el-card shadow="always">
          <el-row>
            <el-col :span="4" :offset="2">
              <div class="demo-fit">
                <div class="block" style="text-align:center">
                  <el-avatar shape="circle" :size="100":src="loginUser.headPortraitUrl?loginUser.headPortraitUrl:'images/zanwuAvatar.png'" fit="cover"></el-avatar>
                  <p align="center" style="font-weight:bold;margin-top: 10px">{{loginUser.name}}</p>
                  <!--                                <p align="center">{{loginUser.studentNumber}}</p>-->
                  <p align="center">{{loginUser.schoolName}}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="16" :offset="1" >
              <el-card class="exiaozhiNumRadius" >
                <el-row class="exiaozhiRowFlex">
                  <el-col :span="8">
                    <div class="myExiaozhiFont">
                      <p>{{Math.floor(playResourceTotalTime/60)}}分钟</p>
                      <p>学习时长</p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="myExiaozhiFont">
                      <p>{{studyTotalDays}}天</p>
                      <p>累计学习</p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="myExiaozhiFont">
                      <p>{{courseTotal}}门</p>
                      <p>累计学习课程</p>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {findPlayResourceTime, findStudyDays, selfCourseList} from "@/api/user";

export default{
    props: {
    },
    data(){
      return{
        courseTotal:0,
        studyTotalDays:0,
        playResourceTotalTime:0,
        loginUser:{}
      }
    },
    created(){
      this.queryUserSelfCourseList();
      this.queryUserStudyInfo();
      this.loginUser=JSON.parse(localStorage.getItem('userInfo'))
    },
    methods:{
      queryUserSelfCourseList(){
        selfCourseList({page:this.coursePage,size:this.courseSize}).then(res=>{
          if(res.code=="0"&&res.data){
            this.courseListData = res.data.records;
            if(res.data.total&&res.data.total>0){
              this.courseTotal = res.data.total;
            }
          }
        })
      },
      queryUserStudyInfo(){
        findStudyDays().then(res=>{
          if(res.code=="0"&&res.data){
            let studyDays = res.data;
            if(studyDays&&typeof(studyDays)!="undefined"){
              this.studyTotalDays = studyDays;
            }
          }
        });
        findPlayResourceTime().then(res=>{
          if(res.code=="0"&&res.data){
            let playResourceTotalTime = res.data;
            if(playResourceTotalTime&&typeof(playResourceTotalTime)!="undefined"){
              this.playResourceTotalTime = playResourceTotalTime;
            }
          }
        })
      },
    }
  }
</script>
<style>

</style>
