<template>
  <div >
    <div style="min-height: 576px">
<!--      <el-input placeholder="请输入内容" v-model="examinationSearch" class="input-with-select">-->
<!--        <el-button slot="append" icon="el-icon-search"></el-button>-->
<!--      </el-input>-->
      <div style="margin-top: 8px" >
        <template v-if="userInfo">
  <!--        学生身份-->
          <el-card v-if="userInfo&&!userInfo.teacherType&&StdroutePath!=='topicList'">
            <div class="stdIntroduceFlex">
              <img :src="userInfo.headPortraitUrl?userInfo.headPortraitUrl:'images/tacitlyAvatar.png'"/>
              <div>
                <p>Hi，{{userInfo.name?userInfo.name:userInfo.telNumber}}</p>
                <p>欢迎回来</p>
              </div>
            </div>
            <div class="organizationPaper" >
              <p class="randomExampaper" v-for="(item,index) in organizationPaper" :key="index" @click="goExPeperList(index)">{{item.name}}</p>
            </div>
          </el-card>
  <!--        教师身份-->
          <el-card v-if="userInfo&&userInfo.teacherType">
            <div class="stdIntroduceFlex">
              <img :src="userInfo.logo?userInfo.logo:'images/tacitlyAvatar.png'"/>
              <div>
                <p>Hi，{{userInfo.name?userInfo.name:userInfo.account}}</p>
                <p>欢迎回来</p>
              </div>
            </div>
            <div class="organizationPaper" >
              <p class="randomExampaper" v-for="(item,index) in teacherShortcut" :key="index" @click="goInstruction(index)">{{item.name}}</p>
            </div>
          </el-card>
        </template>
        <template v-if="!userInfo">
          <div class="stdIntroduceFlex">
            <img src="images/tacitlyAvatar.png"/>
            <div>
              <p>Hi</p>
              <p>暂未登录,请登录</p>
            </div>
          </div>
        </template>
        <div v-if="!routePath">
          <div class="ExaminationFlex">
            <img src="images/Examination.png">
            <p>工具分类</p>
          </div>
          <div class="vocationalInfoFlex">
            <div v-for="(item,index) in vocationalInfo" :key="index" @click="goList(index)">
              <p>{{item.name}}</p>
            </div>
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name:"stdIntroduce",
    data(){
      return{
        examinationSearch:'',
        organizationPaper:[
          {id:1,name:"随机组卷"},
          {id:2,name:"错题记录"},
          {id:3,name:"班级测试"},
          {id:4,name:"专业考试"},
        ],
        teacherShortcut:[
          {id:1,name:"新建测试"},
          {id:2,name:"阅卷管理"},
          {id:3,name:"成绩分析"},
          {id:4,name:"学生管理"},
        ],
        vocationalInfo:[
          {id:1,name:'政策文件'},
          {id:2,name:'头条新闻'},
          {id:3,name:'考纲解读'},
          {id:4,name:'招生信息'},
          {id:5,name:'备考资讯'},
          {id:6,name:'备考课程'},
        ],
        routePath:'',
        userInfo:{},
        StdroutePath:''
      }
    },
    methods:{
    //  去新闻列表页面
      goList(index){
        if(index==5){
          let routeUrl = this.$router.resolve({
            path: "/messageList",
            query:{tabIndex:5}
          });
          window.open(routeUrl.href);
        }else{
          let routeUrl = this.$router.resolve({
            path: "/messageList",
            query:{tabIndex:index}
          });
          window.open(routeUrl.href);
        }
      },
    //  去试卷列表
      goExPeperList(index){
        if(index==2){
          this.$router.push({path:'/topicList',query:{tabIndex:3}})
        }else if(index==3){
          this.$router.push({path:'/topicList',query:{tabIndex:1}})
        }else if(index==1){
          this.$router.push({path:'/topicList',query:{error:'record'}})
        }else if(index==0){
          let routeUrl = this.$router.resolve({
            path: "/randomExamination",
          });
          window.open(routeUrl.href);
        }
      },
    //  去授课中心
      goInstruction(index){
        this.$other.$emit("indexHeaderShow", 1);
        if(index==0){
          let routeUrl = this.$router.resolve({
            path: "/addExaminationPaper",
            query:{vocational:1}
          });
          window.open(routeUrl.href);
        }else if(index==1){
          sessionStorage.setItem('quickEntryShow',3);
          let routeUrl = this.$router.resolve({
            path: "/classCenter",
          });
          window.open(routeUrl.href);
        }else if(index==2){
          sessionStorage.setItem('quickEntryShow',9)
          let routeUrl = this.$router.resolve({
            path: "/classCenter",
            query:{questionContentShow:2}
          });
          window.open(routeUrl.href);
        }else if(index==3){
          sessionStorage.setItem('quickEntryShow',5);
          let routeUrl = this.$router.resolve({
            path: "/classCenter",
          });
          window.open(routeUrl.href);
        }
      }
    },
    mounted() {
      if(this.$route.name=='messageList'){
        this.routePath=this.$route.name
      }
      this.StdroutePath=this.$route.name
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
  }
</script>
<style>
  .stdIntroduceFlex{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .stdIntroduceFlex>img{
    width: 78px;
    margin-right: 10px;
    height: 78px;
    object-fit: contain;
    border-radius: 50%;
  }
  .randomExampaper{
    background: #F2F4F8;
    color: #666666;
    width:36%;
    text-align: center;
    margin: 6px 14px;
    padding: 8px 0;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
  }
  .randomExampaper:hover{
    color: #409EFF;
    font-weight: bold;
  }
  .organizationPaper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .ExaminationFlex{
    display: flex;
    align-items: center;
    margin-top:32px;
    margin-bottom: 12px;
  }
  .ExaminationFlex>img{
    margin-right: 10px;
  }
  .vocationalInfoFlex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 76px;
  }
  .vocationalInfoFlex>div{
    margin:8px 13px;
  }
  .vocationalInfoFlex>div>p{
    width: 34px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    padding: 13px 16px;
    cursor: pointer;
  }
  .vocationalInfoFlex>div>p:hover{
    color: #409EFF;
    font-weight: bold;
  }
</style>
