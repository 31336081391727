import { render, staticRenderFns } from "./examRehearsal.vue?vue&type=template&id=5c6ec6de"
import script from "./examRehearsal.vue?vue&type=script&lang=js"
export * from "./examRehearsal.vue?vue&type=script&lang=js"
import style0 from "./examRehearsal.vue?vue&type=style&index=0&id=5c6ec6de&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports